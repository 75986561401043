import React from 'react'
import Box from '@material-ui/core/Box'
import color from 'theme/color'
import { APPNAME } from 'constants'

const PageInfo = ({
  titleBox,
  title = APPNAME,
  subtitle,
  ...props
}) => {
  if (titleBox) {
    return (
      <Box py={8} px={3} {...props}>
        {title && (
          <Box fontSize={26} fontWeight={700} color={color.primaryColor}>
            {title}
          </Box>
        )}
        {subtitle && (
          <Box fontWeight={500} fontSize={16} color={color.dividerColor}>
            {subtitle}
          </Box>
        )}
      </Box>
    )
  }
  return (
    <Box py={8} px={1} {...props}>
      {title && (
        <Box fontSize={26} fontWeight={700} color={color.primaryColor}>
          {title}
        </Box>
      )}
      {subtitle && (
        <Box fontWeight={500} fontSize={16} color={color.dividerColor}>
          {subtitle}
        </Box>
      )}
    </Box>
  )
}

PageInfo.defaultProps = {
  titleBox: true
}

export default PageInfo
