import React from 'react'

export default function Add () {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 48 48" version="1.1">
      <g id="surface1">
        <path d="M 24 45.65625 C 35.960938 45.65625 45.65625 35.960938 45.65625 24 C 45.65625 12.039062 35.960938 2.34375 24 2.34375 C 12.039062 2.34375 2.34375 12.039062 2.34375 24 C 2.34375 35.960938 12.039062 45.65625 24 45.65625 Z M 24 45.65625 " fill="white" />
        <path d="M 22.152344 32.320312 L 22.152344 25.847656 L 15.679688 25.847656 C 14.65625 25.847656 13.828125 25.019531 13.828125 24 C 13.828125 22.976562 14.65625 22.148438 15.679688 22.148438 L 22.152344 22.148438 L 22.152344 15.675781 C 22.152344 14.65625 22.976562 13.828125 24 13.828125 C 25.023438 13.828125 25.847656 14.65625 25.847656 15.675781 L 25.847656 22.148438 L 32.320312 22.148438 C 33.34375 22.148438 34.171875 22.976562 34.171875 24 C 34.171875 25.019531 33.34375 25.847656 32.320312 25.847656 L 25.847656 25.847656 L 25.847656 32.320312 C 25.847656 33.34375 25.023438 34.167969 24 34.167969 C 22.976562 34.167969 22.152344 33.34375 22.152344 32.320312 Z M 40.738281 7.261719 C 36.265625 2.792969 30.324219 0.328125 24 0.328125 C 17.675781 0.328125 11.734375 2.792969 7.261719 7.261719 C 2.792969 11.734375 0.328125 17.675781 0.328125 24 C 0.328125 30.324219 2.792969 36.265625 7.261719 40.738281 C 11.734375 45.210938 17.675781 47.671875 24 47.671875 C 28.328125 47.671875 32.5625 46.492188 36.25 44.261719 C 37.121094 43.730469 37.402344 42.59375 36.871094 41.722656 C 36.34375 40.847656 35.207031 40.566406 34.332031 41.097656 C 31.226562 42.980469 27.652344 43.972656 24 43.972656 C 12.988281 43.972656 4.027344 35.015625 4.027344 24 C 4.027344 12.988281 12.988281 4.027344 24 4.027344 C 35.011719 4.027344 43.972656 12.988281 43.972656 24 C 43.972656 27.9375 42.800781 31.773438 40.585938 35.085938 C 40.019531 35.9375 40.246094 37.085938 41.097656 37.652344 C 41.945312 38.21875 43.09375 37.992188 43.660156 37.140625 C 46.285156 33.21875 47.671875 28.671875 47.671875 24 C 47.671875 17.675781 45.210938 11.734375 40.738281 7.261719 Z M 40.738281 7.261719 " fill="#1BB89A" />
      </g>
    </svg>
  )
}
