import React from 'react'
import PropTypes from 'prop-types'
import Router from 'next/router'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import Slide from '@material-ui/core/Slide'
import PageInfo from 'components/Layout/PageInfo'
import color from 'theme/color'
import { Toolbar } from '@material-ui/core'
import Image from 'next/image'
import BackIcon from '../../../static/Icon/svg/BackIcon'

const style = {
  container: {
    width: '100%',
    display: 'absolute'
  },
  title: {
    fontWeight: '700',
    color: color.primaryColor
  },
  selected: {
    '&$selected': {
      color: color.primaryColor
    }
  },
  logo: {
    height: '36px',
    width: 'auto'
  },
  primaryColor: {
    color: color.primaryColor
  },
  darkTextColor: {
    height: '50px',
    width: '50px',
    margin: '8px',
    marginTop: '13px'
  },
  icon: {
    display: 'flex',
    alignItems: 'center'
  },
  appbar: {
    alignItems: 'center'
  }
}

const HideOnScroll = (props) => {
  const { children, window } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined })
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

class PrimaryHeaderNavigation extends React.Component {
  render () {
    const {
      classes,
      title = '',
      subtitle,
      centerExtra,
      centerTitle,
      logo,
      iconRight,
      router,
      xsIconLeft,
      iconLeft = (
        <IconButton aria-label="open drawer" onClick={router} className={classes.darkTextColor}>
          <BackIcon />
        </IconButton>
      ),
      titleBox,
      cssJustifyLogo = {},
      HeaderBackgroundColor,
      props,
      xsIconCenter,
      xsIconRight,
      customColor
    } = this.props

    return (
      <div>
        <>
          <HideOnScroll {...props}>
            <AppBar
              elevation={0}
              position="static"
            >
              <Toolbar
                disableGutters
                style={{
                  width: '100%',
                  maxWidth: 600,
                  mx: 'auto',
                  background: customColor || HeaderBackgroundColor,
                  marginLeft: '0px',
                  marginRight: '0px'
                }}
              >
                {centerExtra ? (
                  <Grid direction="row" container justifycontent="space-between" wrap="nowrap">

                    {iconLeft ? (
                      <Grid container item xs={xsIconLeft || 2}>
                        {iconLeft}
                      </Grid>
                    ) : (null)}

                    {centerExtra ? (
                      <Grid container alignItems="center" item xs={xsIconCenter || 8}>
                        {centerExtra}
                      </Grid>
                    ) : (null)}

                    {iconRight ? (
                      <Grid container item xs={xsIconRight || 2}>
                        {iconRight}
                      </Grid>
                    ) : (null)}

                  </Grid>
                ) : (
                  <Grid container wrap="nowrap">
                    {iconLeft ? (
                      <Grid container item sm xs={xsIconLeft}>
                        {iconLeft}
                      </Grid>
                    ) : null}
                    <Grid container item sm>
                      {centerTitle}
                    </Grid>
                    <Grid container item sm justify="flex-end" direction="row">
                      {iconRight}
                    </Grid>
                    {logo && (
                      <Grid container item sm style={cssJustifyLogo}>
                        <Button href="/home">
                          <Image
                            className={classes.logo}
                            loader={({ src }) => {
                              return src
                            }}
                            unoptimized
                            src="/static/app/logo_small.png"
                            alt="logo_small"
                            width={30}
                            height={30}
                          />
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Toolbar>
            </AppBar>
          </HideOnScroll>
        </>
        {title && (
          <PageInfo
            title={title}
            subtitle={subtitle}
            titleBox={titleBox}
          />
        )}
      </div>
    )
  }
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func
}

PrimaryHeaderNavigation.propTypes = {
  classes: PropTypes.object.isRequired,
  HeaderBackgroundColor: PropTypes.string,
  router: PropTypes.func
}

PrimaryHeaderNavigation.defaultProps = {
  HeaderBackgroundColor: color.white,
  router: () => Router.back()
}

export default withStyles(style)(PrimaryHeaderNavigation)
