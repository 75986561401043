import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import color from 'theme/color'
import IconButton from '@material-ui/core/IconButton'
import Add from '@material-ui/icons/Add'
import Remove from '@material-ui/icons/Remove'
import { connect } from 'react-redux'
import { titleCase, currencyFormatter } from 'utils/string'
import Router from 'next/router'
import Image from 'next/image'
import AddLogo from '../../../static/Icon/svg/Add'

const styles = () => ({
  container: {
    height: '100%', backgroundColor: 'white', borderRadius: '10px', padding: '5px'
  },
  buttonQuantity: {
    fontSize: 'larger',
    position: 'absolute',
    width: '90px',
    height: '30px',
    top: '1px',
    right: '8px',
    backgroundColor: color.white,
    color: color.primaryColor,
    borderRadius: '50px',
    textAlign: 'center',
    '&:focus': {
      backgroundColor: color.white
    },
    '&:hover': {
      backgroundColor: color.white
    }
  },
  buttonQuantityCover: {
    position: 'absolute',
    width: '58px',
    height: '55px',
    top: '0px',
    right: '-5px',
    borderRadius: '50px'
  },
  buttonPlus: {
    padding: '0px',
    top: '1px',
    right: '0px',
    position: 'absolute',
    textAlign: 'center',
    borderStyle: 'solid',
    '&:focus': {
      backgroundColor: color.white
    },
    '&:hover': {
      backgroundColor: color.white
    }
  },
  buttonPlusCover: {
    position: 'absolute',
    width: '55px',
    height: '55px',
    top: '0px',
    right: '0px',
    borderRadius: '50px',
    textAlign: 'center',
    border: '3px'
  },
  discountText: {
    zIndex: 1,
    fontWeight: 'bold',
    color: color.danger,
    textDecoration: 'line-through',
    fontSize: '10px',
    paddingLeft: '5px'
  },
  buttonPlus2: {
    fontSize: 'larger',
    position: 'absolute',
    width: '30px',
    height: '30px',
    top: '1px',
    right: '0px',
    backgroundColor: color.white,
    color: color.primaryColor,
    borderRadius: '50px',
    textAlign: 'center',
    '&:focus': {
      backgroundColor: color.white
    },
    '&:hover': {
      backgroundColor: color.white
    }
  },
  buttonPlus2Cover: {
    position: 'absolute',
    width: '55px',
    height: '55px',
    top: '0px',
    right: '0px',
    borderRadius: '50px',
    textAlign: 'center'
  },
  buttonMinus: {
    fontSize: 'larger',
    position: 'absolute',
    width: '30px',
    height: '30px',
    top: '0px',
    right: '1px',
    backgroundColor: color.white,
    color: color.primaryColor,
    borderRadius: '50px',
    textAlign: 'center',
    '&:focus': {
      backgroundColor: color.white
    },
    '&:hover': {
      backgroundColor: color.white
    }
  },
  buttonMinusCover: {
    position: 'absolute',
    width: '55px',
    height: '55px',
    top: '1px',
    right: '60px',
    borderRadius: '50px'
  },
  buttonOpen: {
    position: 'absolute',
    width: '48px',
    height: '48px',
    top: '40px',
    right: '5px',
    backgroundColor: color.white,
    color: color.primaryColor,
    borderRadius: '50px',
    textAlign: 'center',
    '&:focus': {
      backgroundColor: color.white
    },
    '&:hover': {
      backgroundColor: color.white
    }
  },
  clickEffect: {
    position: 'relative',
    height: '100%',
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column'
  },
  objectBehind: {
    zIndex: 1
  },
  overlay: {
    background: '#000000',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    opacity: 0.3,
    borderRadius: '15px'
  },
  overlayText: {
    fontSize: '15px',
    top: '29%',
    left: '50%',
    color: 'white',
    zIndex: 3,
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    fontWeight: 700
  },
  decoration: {
    textDecoration: 'none',
    color: 'inherit'
  }
})

class ProductItem extends Component {
  openProductDetail = (id) => {
    Router.push({
      pathname: '/product-detail',
      query: {
        id
      }
    })
  }

  render () {
    const {
      item,
      classes,
      addQuantity,
      reduceQuantity,
      quantity,
      stateName,
      loading
    } = this.props

    if (!item) return null

    return (
      <span className={classes.clickEffect}>

        <div style={{ position: 'relative', zIndex: 3 }}>
          {quantity >= 1 ? (
            <>
              <div className={classes.buttonQuantityCover} onClick={() => { reduceQuantity(item.id, item, stateName) }}>
                <Button size="small" className={classes.buttonQuantity}>
                  {quantity}
                </Button>
              </div>
              <div className={classes.buttonPlus2Cover}>
                <IconButton disabled={loading} onClick={() => { addQuantity(item.id, item, stateName) }} style={{ color: (quantity >= item.stock ? 'darkgrey' : 'primary') }} className={classes.buttonPlus2} color="primary" aria-label="upload picture" component="span">
                  <Add />
                </IconButton>
              </div>
              <div onClick={() => { reduceQuantity(item.id, item, stateName) }} className={classes.buttonMinusCover}>
                <IconButton disabled={loading} className={classes.buttonMinus} color="primary" aria-label="upload picture" component="span">
                  <Remove />
                </IconButton>
              </div>
            </>
          ) : (
            <div className={classes.buttonPlusCover} onClick={() => { addQuantity(item.id, item, stateName) }}>
              <IconButton className={classes.buttonPlus} color="primary" aria-label="upload picture" component="span">
                <AddLogo />
              </IconButton>
            </div>
          )}
        </div>

        <span>

          {item.stock === 0 ? (
            <>
              <div className={classes.overlayText}>Sedang Tidak Tersedia</div>
              <div className={classes.overlay} />
            </>
          ) : (null)}

          <a className={classes.decoration} href={`/product/${item.productCode}`}>
            <div className={classes.objectBehind}>
              <Image
                style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
                loader={({ src }) => {
                  return src
                }}
                unoptimized
                src={item.image}
                alt={titleCase(item.productName)}
                width={90}
                height={90}
              />
            </div>
            {item.retailPrice > 0 && item.retailPrice > item.sellPrice && (
              <div className={classes.discountText}>
                {currencyFormatter(item.retailPrice)}
              </div>
            )}
            <div className={classes.objectBehind} style={{ fontWeight: 'bold', paddingLeft: '5px' }}>
              {currencyFormatter(item.sellPrice)}
            </div>
            <div className={classes.objectBehind} style={{ paddingLeft: '5px', paddingBottom: '5px' }}>
              {titleCase(item.productName)}
            </div>
          </a>
        </span>
      </span>
    )
  }
}

ProductItem.propTypes = {
  item: PropTypes.object.isRequired,
  stateName: PropTypes.string.isRequired
}

const mapStateToProps = (state) => ({
  userId: state.shoppingCartStore.userId,
  shoppingCartId: state.shoppingCartStore.id,
  successAdd: state.shoppingCartStore.add,
  loading: state.shoppingCartStore.loading
})

export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(ProductItem))
